#components-form-demo-normal-login .login-form-forgot {
  float: right;
}

#components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
  float: left;
}

#components-form-demo-normal-login .login-form-button {
  width: 100%;
}

.ant-form-item-label {
  display: flex;
}

.high-form-item > .ant-form-item-row >.ant-form-item-label > label {
  /* word-wrap: break-word !important; */
  /* white-space: inherit !important; */
  height: 41px !important;
  width: 155px !important;
}

.ant-input,
.ant-select .ant-select-selector,
.ant-input-number,
.ant-input-affix-wrapper {
  border-radius: 6px;
}
.ant-select, .ant-select-selector{
  border-radius: 6px !important;
}
.ant-card {
  border-radius: 6px;
}

.ant-table-pagination.ant-pagination {
  margin: 16px 10px !important;
}

.ant-btn {
  border-radius: 6px;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 6px 10px !important;
  word-break: inherit !important;
}

.ant-form-item {
  margin-bottom: 12px !important;
}

.ant-table {
  overflow-x: scroll !important;
}

.ant-form-item-control {
  line-height: 30px;
}

.ant-list-item .ant-row {
  width: 100%;
}

/*mobile View*/
@media (max-width: 575px) {
  #components-form-demo-normal-login .login-form-forgot {
    padding: 0;
  }
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #31A6C7;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.3);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #455a64 !important;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #021529 !important;
}

.ant-h2 {
  font-size: 24px;
  font-weight: 500;
}

.ant-paddingLeftZero {
  padding-left: 0px !important;
}

.ant-radio-button-wrapper {
  height: 100% !important;
}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

.column_width {
  max-width: 300px !important;
}

.ant-c-popover .ant-popover-inner-content {
  left: 25% !important;
  max-width: 70% !important;
}

.ant-form-item .ant-input-textarea-show-count:after {
  margin-bottom: -16px !important;
}

.circular-widget .ant-list-item-action {
  margin-left: 12px !important;
}

.site-page-header{
  padding:0px !important;
}

.ant-form-item-label{
  font-size: 14px !important;
  font-weight: 600 !important;
  height: 42px !important;
  color: #343434 !important;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after{
  display: inline-block;
  margin-right: 4px;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
  display: none;
}
.ant-form-item-label {
  white-space: normal;
  text-align: left;
}
.mt-40 {
  margin-top: 40px;
}
h2{
  color: #31A6C7;
}
.ant-btn-primary{
  background-color: #31A6C7;
}
.ant-btn-primary:hover{
  background-color: #0492b9 !important;
}
.react-quill-view .ql-container.ql-snow {
  border: none !important;
}
.ant-btn-default:hover,
.ant-btn.ant-btn-lg:hover {
  color: #0492b9 !important;
  border-color: #0492b9 !important;
}
:where(.css-dev-only-do-not-override-2q8sxy) a,
:where(.css-dev-only-do-not-override-2q8sxy).ant-typography a {
  color: #31A6C7 !important;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
}
:where(.css-dev-only-do-not-override-2q8sxy) a:hover,
:where(.css-dev-only-do-not-override-2q8sxy).ant-typography a:hover {
  color: #0492b9 !important;
}
.kanban-table .ant-table-tbody{
  vertical-align: baseline !important;
}