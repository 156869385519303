body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.welcome_c {
  position: absolute;
  top: 29%;
  left: 39%;
}
.quick_link{
  justify-content: space-between;
  align-content: stretch;
}
@media (max-width : 950px ){
.authContainer{
  width : 60vw
}
.welcome-text{
  font-size: 1.2rem !important;
}
.welcome_c {
    position: absolute;
    top: 19%;
    left: 39%;
}
.quick_link{
    justify-content:start;
    align-content: stretch;
}
}
@media (max-width : 950px ){
.authContainer{
  width : 60vw
}
.welcome-text{
  font-size: 1rem !important;

}
.formatted-date-text{
  margin-right : 0px;
}
.welcome-text-wrapper{
  left: 56% !important;
  top: 30%;
  /* position: relative !important; */
  margin-top: 1px;
  margin-bottom: 1px;
  /* width: 80% !important; */
}

.user-img-dashboard{
height: 60% !important;
top: 50px
}
}
@media screen and (max-width: 768px) {
  body {
    overflow-y: auto;
  }
}
.welcome-text{
  font-size: 1.8rem;
  font-weight: 600;
}
.order-card-content-number{
  color : #363565;
  font-size: 1.8rem !important;
  font-weight: 800;
  text-align: center;
  font-family: Nunito Sans, Sans Serif;
}
.text-3 {
  font-size: 12px !important;
  font-weight: bold !important;
}

.btn-primary{
  background-color: #31A6C7;
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-menu-light .ant-menu-item-selected,
:where(.css-dev-only-do-not-override-2q8sxy).ant-menu-light > .ant-menu .ant-menu-item-selected {
  color: #31A6C7;
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title,
:where(.css-dev-only-do-not-override-2q8sxy).ant-menu-light > .ant-menu .ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: #31A6C7;
}
:where(.css-2q8sxy).ant-menu-light .ant-menu-item-selected,
:where(.css-2q8sxy).ant-menu-light > .ant-menu .ant-menu-item-selected {
  color: #31A6C7;
}
:where(.css-2q8sxy).ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title,
:where(.css-2q8sxy).ant-menu-light > .ant-menu .ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: #31A6C7;
}
.range-picker > .ant-picker-input > input {
  font-size: 0.7em !important;
}
